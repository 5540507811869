import jQuery from 'jquery';
global.jquery = jQuery;
global.$ = jQuery;
window.$ = window.jQuery = require('jquery');

import slick from 'slick-carousel';
import 'slick-carousel/slick/slick.css';

$(function(){

  var isMobile=$('.mobile').is(':visible');

  $('.banners .container>div').slick({
    //centerMode: true,
    arrows: false,
    slidesToShow: 4,
    autoplay:true,
    autoplaySpeed:5000
  });

  $('a[href^="#"]').click(function(){
    var a=$(this).attr('href');
    if(a=='#') return false;
    var o=$(a);
    if(o.length<1) return false;
    var y=o.offset().top-$('header').height()
    $('html,body').animate({scrollTop:y});
    return false;
  });

  $('.news__side a[href="#"]').click(function(){
    $(this).next('ul').slideToggle();
  });

/*
  $('.home__concept .more a').eq(0).click(function(){
    var y=$('.home__concept > .container > div:eq(1)').offset().top-$('header').height();
    $('html,body').animate({scrollTop:y});
    return false;
  });

  $('.home__concept .more a').eq(1).click(function(){
    var y=$('.point__header').offset().top-$('header').height();
    $('html,body').animate({scrollTop:y});
    return false;
  });
*/


  $('.header__menu a').click(function(){
    $('header').addClass('open');
    return false;
  });

  $('.header__close a').click(function(){
    $('header').removeClass('open');
    return false;
  });

  $('.page__header').wrapInner('<span></span>');

  $('.rewind a').click(function(){
    $('html,body').animate({scrollTop:0});
    return false;
  });

  var scrollElements=[];
  var scrollPositions=[];


  $(window).on('load',function(){

    var o=$(location.hash);
    if(o.length>0){
      $(window).scrollTop(o.offset().top-$('header').height());
    }

    $('.gallery__item').each(function(){
      $(this).height($(this).height());
    });

    var s=$('.gallery__slide').slick({
      //centerMode: true,
      autoplay: true
    });

    var a=$('.gallery__thumb a');
    a.click(function(){
      s.slick('slickGoTo',a.index(this));
      return false;
    });


    $('.cover__slide').on('init',function(){
      $('.cover').addClass('show');
      $('.cover').addClass('zoom');

    }).slick({
      fade: true,
      speed: 2500,
      autoplaySpeed:3500,
      autoplay: true
    }).slick('slickPause');

    $('.cover__slide').on('webkitTransitionEnd mozTransitionEnd oTransitionEnd onTransitionEnd',function(){
      $('.cover__slide').slick('slickPlay');
    });

    $('.home__concept>.container>div,.guide__row').each(function(i,e){
      scrollElements.push(e);
      scrollPositions.push($(e).offset().top);
    });


  });

  var lastScroll;
  $(window).on('scroll',function(){

    var y = $(window).scrollTop();
    var h = window.innerHeight;
    var d = y-lastScroll;
    lastScroll=y;

    if(y<=0){
      $('.scroll-in').removeClass('scroll-in');
      return;
    }

    if(y>h*0.2){
      $('.tools').addClass('scroll-in');
    }

    $.each(scrollPositions,function(i,e){
      if(y>e-h+0){
        $(scrollElements[i]).addClass('scroll-in');
      }
    });

    if(isMobile) return;

    $('.home__concept .scroll-in').each(function(i,e){
      var o=$(e);
      var oy=scrollAmount(o);
      o.find('img').css({transform:'translateY('+(oy*0.34)+'px)'});
    });

/*
    if(d>0){
      var sy1=600; //y方向遅れ
      var sy2=660; //y方向遅れ2
      var dx=0.3; //x方向遅れ
      $('.guide__row.scroll-in:first-child').each(function(i,e){
        var o=$(e);
        var oy=scrollAmount(o);
        o.find('.guide__fig').css({transform:'translateX('+max((oy-sy2)*dx)+'%)'});
        o.find('.guide__desc').css({transform:'translateX('+min((oy-sy1)*(-dx))+'%)'});
      });

      $('.guide__row.scroll-in:last-child').each(function(i,e){
        var o=$(e);
        var oy=scrollAmount(o);
        o.find('.guide__desc').css({transform:'translateX('+max((oy-sy1)*dx)+'%)'});
        o.find('.guide__fig').css({transform:'translateX('+min((oy-sy2)*(-dx))+'%)'});
      });
    }
*/

    function scrollAmount(o){
      return y-o.offset().top+h;
    }
    function max(x){
      if(x>=0) return 0; else return x;
    }
    function min(x){
      if(x<=0) return 0; else return x;
    }
  });

});


